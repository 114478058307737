import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
//require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';

$(document).foundation();

const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();

AOS.init({

  offset: 20, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 250, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
	
});

$('.menu-icon').click(function() {
    $(this).toggleClass('active');
});

$(window).scroll(function() {
	
  var header = $(document).scrollTop();
  var headerHeight = $("header, .title-bar").outerHeight();
  var firstSection = $(".banner-section .grid-container, .content-section .grid-container").outerHeight();

//  if( $('.top-bar').attr('style').display == 'none' ) {  

	  if (header > headerHeight) {
	    $("header, .title-bar").addClass("fixed");   
	  } else {
	    $("header, .title-bar").removeClass("fixed");  
	  }


	  if (header > firstSection) {
	    $("header, .title-bar").addClass("in-view");
	  } else {
	    $("header, .title-bar").removeClass("in-view");
	    $(".banner-section").addClass("scrolled");      
	    $(".content-section").addClass("scrolled");          
	  }  
//	} 
  
});	

jQuery(function($) {
 var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
 $('.title-bar a').each(function() {
  if (this.href === path) {
   $(this).addClass('active');
  }
 });
});

